<template>
  <div id="information">
    <div class="title">填写个人信息</div>
    <div class="form">
      <input-information
        v-for="(item, i) in inputInformation"
        :key="i"
        :input-information-obj="item"
      ></input-information>
      <option-information
        :user-country-obj="userCountryObj"
        :user-province-obj="userProvinceObj"
        :user-city-obj="userCityObj"
      ></option-information>
    </div>
    <button
      @click="submitInformation"
      class="btn"
      :style="{
        backgroundColor: primaryColor,
      }"
    >
      下一步
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { getUserQuestionnaire } from "@/api/information";
import inputInformation from "@/views/form/information/components/inputInformation";
import optionInformation from "@/views/form/information/components/optionInformation";

import QuestionnaireQuestionService from "../../../service/questionnaire-question";
import UserQuestionAnswerService from "../../../service/user-question-answers";
import UserVisitRecordService from "../../../service/user-visit-record";

export default {
  name: "Information",
  components: { inputInformation, optionInformation },
  computed: {
    ...mapState({
      primaryColor: (state) => state.application.properties.primaryColor,
      pickerTypes: (state) => state.pickerTypes,
      fieldTypes: (state) => state.fieldTypes,
      application: (state) => state.application,
      account: (state) => state.account,
      user: (state) => state.user,
      phone: (state) => state.phone,
      exhibition: (state) => state.exhibition,
      businessCard: (state) => state.businessCard,
      visitRecord: (state) => state.visitRecord,
      userQuestionnaireId: (state) =>
        state.application.properties.userQuestionnaireId,
      questionnaireId: (state) => state.application.properties.questionnaireId,
    }),
  },
  data() {
    return {
      isLoading: false,
      questions: [],
      rules: [],
      information: {},

      inputInformation: [],
      optionInformation: [],
      userCountryObj: {},
      userProvinceObj: {},
      userCityObj: {},
      questionList: [],
      informationCount: null,
      answers: [], // 放答案的数组
    };
  },
  async mounted() {
    const quesions = await QuestionnaireQuestionService.getQuestionnaireQuestions(
      {
        id: this.application.properties.userQuestionnaireId,
      }
    );

    this.quesions = quesions;

    this.answers = [];
    getUserQuestionnaire(
      this.$store.state.application.properties.userQuestionnaireId
    ).then((res) => {
      if (res.status === 200 && res.data) {
        this.questionList = res.data;
        for (let [index, item] of res.data.entries()) {
          item.index1 = index;
          if (
            item.type !== "USER_COUNTRY" &&
            item.type !== "USER_PROVINCE" &&
            item.type !== "USER_CITY"
          ) {
            this.inputInformation.push(item);
          } else if (item.type === "USER_COUNTRY") {
            this.userCountryObj = item;
          } else if (item.type === "USER_PROVINCE") {
            this.userProvinceObj = item;
          } else if (item.type === "USER_CITY") {
            this.userCityObj = item;
          }
          let answer = { question: { id: item.id }, value: "" };
          this.answers.push(answer);
        }
      }
    });
  },
  methods: {
    ...mapActions({
      getVisitRecord: "getVisitRecord",
    }),
    async submitInformation() {
      try {
        this.isLoading = true;

        if (this.answerValidate()) {
          await UserQuestionAnswerService.addUserQuestionAnswers(
            this.user,
            this.answers
          );

          let properties = { questionnaires: [] };
          for (let i = 0; i < this.questionList.length; i++) {
            properties[this.questionList[i].key] = this.answers[i].value;
          }
          properties.questionnaires.push(
            this.$store.state.application.properties.userQuestionnaireId
          );

          const params = this.visitRecord;

          params.properties = {
            ...params.properties,
            ...properties,
            applicationId: this.application.id,
            questionnaires: [this.userQuestionnaireId],
          };

          params.progress = "PERFECT_INFORMATION";

          await UserVisitRecordService.updateUserVisitRecord(this.user, params);

          await this.getVisitRecord();

          this.$router.push("/index/questionnaire");
        } else {
          this.$message({
            type: "error",
            message: "您还有必填信息未填写！",
            duration: 2000,
          });
        }
      } catch (error) {
        console.log(error);

        this.$message({
          type: "error",
          message: error.response ? error.response.data.message : error.message,
          duration: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    answerValidate() {
      let valid = true;
      for (let i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].required && !this.answers[i].value) {

          console.log(this.questionList[i]);

          valid = false;
          break;
        }
        return valid;
      }
    },
  },
};
</script>

<style scoped lang="scss">
#information {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
    margin: 24px 0;
  }

  .form {
    min-width: 452px;
  }

  .btn {
    margin: 30px 0;
    width: 320px;
    height: 40px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    line-height: 28px;
    letter-spacing: 10px;
    border: none;
    outline: none;
  }
}
</style>
