import axios from "@/utils/request";

export function getUserQuestionnaire(userQuestionnaireId) {
  return axios({
    url: `/questionnaire/${userQuestionnaireId}/question`,
    method: 'get',
  })
}

export function submitInformation(userId, dataForm) {
  return axios({
    url: `/user/${userId}/question-answers`,
    method: 'post',
    data: dataForm
  })
}
