<template>
  <div id="userName">
      <p class="required" style="color: #FF442F; width: 5px; margin-right: 5px" v-if="inputInformationObj.required">*</p>
      <p style="width: 5px; height: 5px; margin-right: 5px" v-if="!inputInformationObj.required"></p>
      <p class="label">{{ inputInformationObj.content }}</p>
    <div class="input">
      <input v-model="dataForm" type="text" :placeholder="setPlaceholder()" @blur="setAnswer" :disabled="inputInformationObj.type === 'USER_PHONE'">
      <p class="tips" :style="{'color': $store.state.application.properties.primaryColor}">{{ inputInformationObj.description }}</p>
    </div>
    <p class="error" :style="{'color': $store.state.application.properties.primaryColor}">{{errorMessage}}</p>
  </div>
</template>

<script>
export default {
  name: "UserName",
  props: {
    inputInformationObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataForm: '',
      errorMessage: '',
      answerIndex: ''
    }
  },
  created() {
    this.answerIndex = this.inputInformationObj.index1;
    if (this.inputInformationObj.type === 'USER_PHONE') {
      this.dataForm = this.$store.state.phone.number || '';
      this.setAnswer();
    } else if (this.inputInformationObj.type === 'USER_NAME') {
      // this.dataForm = this.$store.state.user.name || '';
      // this.setAnswer();
    }
  },
  methods: {
    setAnswer() {
      if (!this.dataForm && this.inputInformationObj.required) {
        console.log(`${this.inputInformationObj.content}为必填项`);
        // this.errorMessage = `${this.inputInformationObj.content}为必填项`
      } else {
        if (this.validate(this.dataForm)) {
          this.$parent.answers[this.answerIndex].value = this.dataForm;
        } else {
          this.$parent.answers[this.answerIndex].value = '';
        }
      }
    },
    // 设置placeHolder
    setPlaceholder() {
      return `请输入${this.inputInformationObj.content}`;
    },

    // 简单的内容验证
    validate(value) {
      const MOBILE_NUMBER_REG = /^1[3456789]\d{9}$/;
      const EMAIL_REG = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      let valid
      switch (this.inputInformationObj.type) {
        case 'USER_PHONE':
          valid = MOBILE_NUMBER_REG.test(value);
          if (!valid) {
            this.errorMessage = '格式不正确'
          } else {
            this.errorMessage = '';
          }
          break;
        case 'USER_EMAIL':
          valid = EMAIL_REG.test(value);
          if (!valid) {
            this.errorMessage = '格式不正确';
          } else {
            this.errorMessage = ''
          }
          break;
        default:
          valid = true;
          this.errorMessage = ''
          break;
      }
      return valid
    }
  }
}
</script>

<style scoped lang="scss">
#userName {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 40px;
  position: relative;
  .error {
    width: 100px;
    position: absolute;
    left: 460px;
    top: 10px;
    font-size: 12px;
  }
  p {
    margin-bottom: 0;
  }
  .required {
    max-width: 5px;
  }
  .label {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #646464;
    margin-right: 10px;
    box-sizing: border-box;
  }

  .input {
    position: relative;
    min-width: 200px;
    flex-grow: 2;
    input {
      width: 100%;
      height: 35px;
      box-sizing: border-box;
      background-color: #F9FBFF;
      padding: 8px;
      border: 1px solid #DDDDDD;
    }



    .tips {
      position: absolute;
      top: 40px;
      left: 0;
      font-size: 12px;
    }
  }

}
</style>
