

export default {

    addUserQuestionAnswers: async (user, questionAnswerParams) => {

        const questionAnswers = await window.$axios({
            url: `/user/${user.id}/question-answers`,
            method: "POST",
            data: questionAnswerParams,
        });

        return questionAnswers
    },
}